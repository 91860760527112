<template>
  <v-container no-gutters pa-0 fluid>
    <entity-location-picker @selected="selectMoveMap" />
    <v-row xs12 no-gutters style="height: 75vh">
      <MglMap
        :accessToken="accessToken"
        :mapStyle.sync="mapstyle"
        :zoom.sync="zoom"
        @moveend="moveMap"
        @zoomend="moveMap"
        @load="onMapLoaded"
        @
      >
        <MglMarker ref="myMarker" anchor="bottom" :coordinates="popupCoordinates">
          <template marker=""></template>
          <MglPopup :close-button="false" max-width="40%">
            <v-card class="mx-auto" flat outlined>
              <v-card-text>
                <div class="text-center">{{ slotProps.name }}</div>
                <p class="display-1 text--primary text-center">
                  {{ slotProps.reference }}
                </p>
              </v-card-text>
              <v-card-actions>
                <v-btn text class="mx-auto pa-1"><router-link :to="{ name: 'park', params: { id: slotProps.reference } }" style="text-decoration: none">More Info</router-link></v-btn>
              </v-card-actions>
            </v-card>
          </MglPopup>
        </MglMarker>

        <!-- <MglPopup ref="myPopup" anchor="top" :coordinates="popupCoordinates" :close-button="false" max-width="40%">
          <v-card class="mx-auto" flat outlined>
            <v-card-text>
              <div class="text-center">{{ slotProps.name }}</div>
              <p class="display-1 text--primary text-center">
                {{ slotProps.reference }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn text class="mx-auto pa-1"><router-link :to="{ name: 'parks', params: { id: slotProps.reference } }" style="text-decoration: none">More Info</router-link></v-btn>
            </v-card-actions>
          </v-card>
        </MglPopup> -->

        <MglGeojsonLayer
          sourceId="parkSourceId"
          :source="geoJsonSource"
          layerId="parkLayerId"
          :layer="geojsonLayer"
          @click="clickParkInfo"
          @mouseenter="mouseEnter"
          @mouseleave="mouseLeave"
          replaceSource
        />
        <MglNavigationControl />
        <MglGeolocateControl />
        <MglScaleControl position="top-left" />
      </MglMap>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import EntityLocationPicker from '@/components/EntityLocationPicker'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'v-mapbox/dist/v-mapbox.css';

import {
  MglMap,
  MglMarker,
  MglGeojsonLayer,
  MglNavigationControl,
  MglPopup,
  MglGeolocateControl,
  MglScaleControl
} from 'v-mapbox'


export default {
  components: {
    EntityLocationPicker,
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    MglNavigationControl,
    MglPopup,
    MglGeolocateControl,
    MglScaleControl
  },
  props: {
    parkinfo: null
  },
  data() {
    return {
      accessToken: 'pk.eyJ1IjoibjBhdyIsImEiOiJjand6aW5vMHoxZ29qNDluNW1rcnQ1Ymw3In0.Maco-UBPruDo_Gh13oBKPw',
      
      layerId: 'parkLayerId',
      sourceId: 'parkSourceId',
      geoJsonSource: {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [102.0, 0.5]
              },
              properties: {
                id: "value0"
              }
            }
          ]
        },
      },
      geojsonLayer: {
        type: "circle",
        paint: {
          "circle-color": "yellow",
          "circle-stroke-color": "black",
          "circle-stroke-width": 1
        }
      },
      geoJsonUrl: '',
      geojson: null,
      loading: false,
      location: null,
      locations: null,

      mapstyle: 'mapbox://styles/mapbox/outdoors-v11',
      mapstyles: [
        {
          text: "Streets View",
          value: "mapbox://styles/mapbox/streets-v11"
        },
        {
          text: "Outdoors View",
          value: "mapbox://styles/mapbox/outdoors-v11",
        },
        {
          text: "Light View",
          value: "mapbox://styles/mapbox/light-v10",
        },
        {
          text: "Dark View",
          value: "mapbox://styles/mapbox/dark-v10",
        },
        {
          text: "Satellite View",
          value: "mapbox://styles/mapbox/satellite-v9",
        },
        {
          text: "Satellite Streets View",
          value: "mapbox://styles/mapbox/satellite-streets-v11",
        },
        {
          text: "Navigation Preview Day",
          value: "mapbox://styles/mapbox/navigation-preview-day-v4",
        },
        {
          text: "Navigation Preview Night",
          value: "mapbox://styles/mapbox/navigation-preview-night-v4",
        },
        {
          text: "Navigation Guidance Day",
          value: "mapbox://styles/mapbox/navigation-guidance-day-v4",
        },
        {
          text: "Navigation Guidance Night",
          value: "mapbox://styles/mapbox/navigation-guidance-night-v4",
        }
      ],

      zoom: 9,
      center: [0,0],
      myPos: [0,0],
      popupCoordinates: [0,0],

      slotProps: {
        name: "",
        reference: "",
        entityName: "",
        locationDesc: ""
      },

      model: null,
      search: null
    };
  },
  beforeMount() {
    this.getLocations();
  },
  mounted () {},
  created() {
    // this.map = null;
  },
  methods: {
    onMapLoaded(event) {
      // alert("func onMapLoaded")
      // in component
      this.map = event.map;
      // or just to store if you want have access from other components
      this.$store.map = event.map;

      if (this.parkinfo)
      {
        var userCoords = {'lat': this.parkinfo.latitude, 'lng': this.parkinfo.longitude};
        //console.log(userCoords);
        this.centerUpdate(userCoords);
        if (this.parkinfo.zoom)
          this.zoom = this.parkinfo.zoom;

        this.popupCoordinates = [ this.parkinfo.longitude, this.parkinfo.latitude ];
        this.slotProps = {
          name: this.parkinfo.name,
          reference: this.parkinfo.reference,
          entityName: this.parkinfo.entityName,
          locationDesc: this.parkinfo.locationDesc
        };
        //this.$refs.myMarker.togglePopup();
      }
      else
      {
        this.geolocation();
        this.restoreMapPosition();
        this.restoreMapPins();
      }

      this.updateMapPins(event);
    },
    handleClick(value) {
      alert(value)
    },

    debugObject(obj) {
      for (const key in obj) {
        console.log(`${key}: ${obj[key]}`);
      }
    },

    // On mouse enter, change cursor to a pointer
    mouseEnter(event) {
      event.map.getCanvas().style.cursor = 'pointer';
    },

    mouseLeave(event) {
      event.map.getCanvas().style.cursor = '';
    },

    // Handler function when someone select a location to move the map
    selectMoveMap(value) {
      this.centerUpdate({ 'lat': value.latitude, 'lng': value.longitude})
    },
    // selectMapStyle() {
    //   this.addDataLayer()
    // },
    getLocations() { 
      axios
        .get(`https://${process.env.VUE_APP_API_URL}/locations`)
        .then(response => {
          this.locations = response.data;
        })
    },
    geolocation() {
      // Ask the user for their location to add to the store
      if (this.$store.state.user.latlong == null) {
        navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoError);
      }
      // this.restoreMapPosition()
    },
    restoreMapPosition() {
      // alert("func restoreMapPosition")
      // Look for center
      if (this.$store.state.map.center_latlong != null) {
        this.centerUpdate(this.$store.state.map.center_latlong)
      } else if (this.$store.state.user.latlong != null) {
        this.centerUpdate(this.$store.state.user.latlong)
      } else {
        this.centerUpdate({'lat': 40, 'lng': -122})
      }

      if (this.$store.state.map.zoom != null) {
        this.zoom = this.$store.state.map.zoom
      } else {
        this.zoom = 9
      }
    },
    restoreMapPins() {
      if (this.$store.state.map.pins_url != null) {
        //alert("restoring from "+ this.$store.state.map.pins_url)

        this.geoJsonSource = {
          type: "geojson",
          data: this.$store.state.map.pins_url
        }
        //console.log(this.geoJsonSource)
      }
    },
    geoSuccess(loc) {
      // Store the user's current location in the store so that we don't keep asking them for it
      this.$store.commit('SET_LATLONG', {'lat': loc.coords.latitude, 'lng': loc.coords.longitude});

      // If the store contains a null value for the map center, use this one
      this.$store.commit('SET_CENTER_LATLONG', {'lat': loc.coords.latitude, 'lng': loc.coords.longitude})

      this.myPos = {'lat': loc.coords.latitude, 'lng': loc.coords.longitude}
      this.centerUpdate(this.myPos)
    },
    geoError() {
      // almost always user denied access
      this.centerUpdate({'lat': 0, 'lng': 0})
      this.currentCenter = {'lat': 0, 'lng': 0}
      this.myPos = {'lat': 0, 'lng': 0}
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.center = center;
      if (this.map)
        this.map.setCenter(center)
    },
    clickParkInfo(event) {
      var coordinates = event.mapboxEvent.features[0].geometry.coordinates.slice();
      // var description = value.features[0].properties.description;
      
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(event.mapboxEvent.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += event.mapboxEvent.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      this.popupCoordinates = coordinates
      this.slotProps = event.mapboxEvent.features[0].properties
      // this.debugObject(event.mapboxEvent.features[0].properties)
      // this.debugObject(this.$refs.myPopup) // .addTo(event.map)
      this.$refs.myMarker.togglePopup()
    },
    changeStyle(value) {
      this.map.setStyle(value);
    },

    moveMap(event) {
      // var map = event.map
      //console.log(event);
      
      // Store center/zoom so that the map stays the same across page requests
      this.$store.commit('SET_CENTER_LATLONG', this.center);
      this.$store.commit('SET_ZOOM', this.zoom);
      this.updateMapPins(event)
    },
    updateMapPins(event) {
      if (this.map.getZoom() < 7)
        {console.log('zoom out of range'); return;}
        //return // only get park features if at state level or closer

      //console.log("func updateMapPins")
      // Get bounding box of coords to query the API for parks
      var bounds = this.map.getBounds()
      var sw = bounds.getSouthWest()
      var ne = bounds.getNorthEast()
      var url = `https://${process.env.VUE_APP_API_URL}/park/grids`+"/"+sw.lat.toFixed(1)+"/"+sw.lng.toFixed(1)+"/"+ne.lat.toFixed(1)+"/"+ne.lng.toFixed(1)+"/0"
      this.$store.commit('SET_PINS_URL', url);

      if (this.geoJsonUrl == url)
        return;

      this.geoJsonUrl = url;

      //if (event == undefined) {
      //  alert("event undefined")
      //}

      //if (this.map == undefined) {
      //  alert("event.map undefined")
      //}

      //this.geoJsonSource = {
      //    type: "geojson",
      //    data: url
      //  };

      if (event.map.getSource('parkSourceId') != undefined) {
        console.log("updateMapPins setData " + url)
        event.map.getSource('parkSourceId').setData(url)
      }
    }
  },
  computed: {
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction
      };
    },

    styleFunction() {
      const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        return {
          weight: 2,
          color: "#ECEFF1",
          opacity: 1,
          fillColor: fillColor,
          fillOpacity: 1
        };
      };
    },

    onEachFeatureFunction() {
      return (feature, layer) => {
        layer.bindPopup(
          "<div>" + 
          "<strong>Name: </strong>" + feature.properties.name + "<br>" +
          "<strong>Reference: </strong>" + feature.properties.reference + "" +
          "</div>",
          { permanent: false, sticky: true }
        );
      };
    },

    fields () {
      if (!this.model) return []

      return Object.keys(this.model).map(key => {
        return {
          key,
          value: this.model[key] || 'n/a',
        }
      });
    },

    items () {
      return this.entries.map(entry => {
        const Description = entry.Description.length > this.descriptionLimit
          ? entry.Description.slice(0, this.descriptionLimit) + '...'
          : entry.Description

        return Object.assign({}, entry, { Description })
      })
    },
  },

  watch: {
    search (val) {
      // Items have already been loaded
      if (this.items.length > 0) return

      // Items have already been requested
      if (this.isLoading) return
      this.isLoading = val
      this.isLoading = true

      // Lazily load input items
      fetch('https://${process.env.VUE_APP_API_URL}/locations')
        .then(res => res.json())
        .then(res => {
          const { count, entries } = res
          this.count = count
          this.entries = entries
        })
        // .catch(err => {
        //   console.log(err)
        // })
        .finally(() => (this.isLoading = false))
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
